<template>
	<div class="MflSecurity">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl-t"></div>
			<div class="zhuangshizj fl-t"> {{ projectData.project }}_运行考核</div>
			<div class="zhuangshiyb fl-t"></div>
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->

		<div class="sjxs fl-t">
			<div class="row">
				数据显示
			</div>
			<div class="throw flex">
				<div class="c1 fl-t">序号</div>
				<div class="c2 fl-t">班运行</div>
				<div class="c3 fl-t">天运行</div>
				<div class="c4 fl-t">月运行</div>
				<div class="c5 ">自定义运行</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">给水累计(T)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_GSLJB', 'KHG', 'KH_GSLJB_BM','给水班累计')"
					@dblclick="Cclick(infoList.KHG.KH_GSLJB,'KH_GSLJB', 'KHG', 'KH_GSLJB_BM')">{{infoList.KHG.KH_GSLJB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_GSLJT', 'KHG', 'KH_GSLJT_BM','给水天累计')"
					@dblclick="Cclick(infoList.KHG.KH_GSLJT,'KH_GSLJT', 'KHG', 'KH_GSLJT_BM')">{{infoList.KHG.KH_GSLJT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_GSLJY', 'KHG', 'KH_GSLJY_BM','给水月累计')"
					@dblclick="Cclick(infoList.KHG.KH_GSLJY,'KH_GSLJY', 'KHG', 'KH_GSLJY_BM')">{{infoList.KHG.KH_GSLJY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_GSLJS', 'KHG', 'KH_GSLJS_BM','给水自定义考核累计')"
					@dblclick="Cclick(infoList.KHG.KH_GSLJS,'KH_GSLJS', 'KHG', 'KH_GSLJS_BM')">{{infoList.KHG.KH_GSLJS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">蒸汽累计(T)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_ZQLJB', 'KHG', 'KH_ZQLJB_BM','蒸汽班累计')"
					@dblclick="Cclick(infoList.KHG.KH_ZQLJB,'KH_ZQLJB', 'KHG', 'KH_ZQLJB_BM')">{{infoList.KHG.KH_ZQLJB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_ZQLJT', 'KHG', 'KH_ZQLJT_BM','蒸汽天累计')"
					@dblclick="Cclick(infoList.KHG.KH_ZQLJT,'KH_ZQLJT', 'KHG', 'KH_ZQLJT_BM')">{{infoList.KHG.KH_ZQLJT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_ZQLJY', 'KHG', 'KH_ZQLJY_BM','蒸汽月累计')"
					@dblclick="Cclick(infoList.KHG.KH_ZQLJY,'KH_ZQLJY', 'KHG', 'KH_ZQLJY_BM')">{{infoList.KHG.KH_ZQLJY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_ZQLJS', 'KHG', 'KH_ZQLJS_BM','蒸汽自定义考核累计')"
					@dblclick="Cclick(infoList.KHG.KH_ZQLJS,'KH_ZQLJS', 'KHG', 'KH_ZQLJS_BM')">{{infoList.KHG.KH_ZQLJS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">煤量(Wm³)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_GLLJB', 'KHG', 'KH_GLLJB_BM','给煤班累计')"
					@dblclick="Cclick(infoList.KHG.KH_GLLJB,'KH_GLLJB', 'KHG', 'KH_GLLJB_BM')">{{infoList.KHG.KH_GLLJB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_GLLJT', 'KHG', 'KH_GLLJT_BM','给煤天累计')"
					@dblclick="Cclick(infoList.KHG.KH_GLLJT,'KH_GLLJT', 'KHG', 'KH_GLLJT_BM')">{{infoList.KHG.KH_GLLJT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_GLLJY', 'KHG', 'KH_GLLJY_BM','给煤月累计')"
					@dblclick="Cclick(infoList.KHG.KH_GLLJY,'KH_GLLJY', 'KHG', 'KH_GLLJY_BM')">{{infoList.KHG.KH_GLLJY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_GLLJS', 'KHG', 'KH_GLLJS_BM','给煤自定义考核累计')"
					@dblclick="Cclick(infoList.KHG.KH_GLLJS,'KH_GLLJS', 'KHG', 'KH_GLLJS_BM')">{{infoList.KHG.KH_GLLJS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">吨汽耗(煤)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_MQHB', 'KHG', 'KH_MQHB_BM','班吨蒸汽煤耗')"
					@dblclick="Cclick(infoList.KHG.KH_MQHB,'KH_MQHB', 'KHG', 'KH_MQHB_BM')">{{infoList.KHG.KH_MQHB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_MQHT', 'KHG', 'KH_MQHT_BM','天吨汽煤耗')"
					@dblclick="Cclick(infoList.KHG.KH_MQHT,'KH_MQHT', 'KHG', 'KH_MQHT_BM')">{{infoList.KHG.KH_MQHT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_MQHY', 'KHG', 'KH_MQHY_BM','月吨蒸汽煤耗')"
					@dblclick="Cclick(infoList.KHG.KH_MQHY,'KH_MQHY', 'KHG', 'KH_MQHY_BM')">{{infoList.KHG.KH_MQHY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_MQHS', 'KHG', 'KH_MQHS_BM','自定义吨蒸汽煤耗')"
					@dblclick="Cclick(infoList.KHG.KH_MQHS,'KH_MQHS', 'KHG', 'KH_MQHS_BM')">{{infoList.KHG.KH_MQHS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">风机电耗(kw)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_DHB', 'KHG', 'KH_DHB_BM','电耗班累计')"
					@dblclick="Cclick(infoList.KHG.KH_DHB,'KH_DHB', 'KHG', 'KH_DHB_BM')">{{infoList.KHG.KH_DHB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_DHT', 'KHG', 'KH_DHT_BM','电耗日累计')"
					@dblclick="Cclick(infoList.KHG.KH_DHT,'KH_DHT', 'KHG', 'KH_DHT_BM')">{{infoList.KHG.KH_DHT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_DHY', 'KHG', 'KH_DHY_BM','电耗月累计')"
					@dblclick="Cclick(infoList.KHG.KH_DHY,'KH_DHY', 'KHG', 'KH_DHY_BM')">{{infoList.KHG.KH_DHY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_DHS', 'KHG', 'KH_DHS_BM','电耗自定义累计')"
					@dblclick="Cclick(infoList.KHG.KH_DHS,'KH_DHS', 'KHG', 'KH_DHS_BM')">{{infoList.KHG.KH_DHS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">自控率(%)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_ZKLB', 'KHG', 'KH_ZKLB_BM','班自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZKLB,'KH_ZKLB', 'KHG', 'KH_ZKLB_BM')">{{infoList.KHG.KH_ZKLB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_ZKLT', 'KHG', 'KH_ZKLT_BM','天自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZKLT,'KH_ZKLT', 'KHG', 'KH_ZKLT_BM')">{{infoList.KHG.KH_ZKLT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_ZKLY', 'KHG', 'KH_ZKLY_BM','月自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZKLY,'KH_ZKLY', 'KHG', 'KH_ZKLY_BM')">{{infoList.KHG.KH_ZKLY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_ZKLS', 'KHG', 'KH_ZKLS_BM','自定义考核自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZKLS,'KH_ZKLS', 'KHG', 'KH_ZKLS_BM')">{{infoList.KHG.KH_ZKLS}}
				</div>
			</div>
			<div class="tdrow">
				<div class="c1 fl-t">稳定性(%)</div>
				<div class="c2 fl-t" @click="toCompon(2, 'KH_WDXB', 'KHG', 'KH_WDXB_BM','班稳定性')"
					@dblclick="Cclick(infoList.KHG.KH_WDXB,'KH_WDXB', 'KHG', 'KH_WDXB_BM')">{{infoList.KHG.KH_WDXB}}
				</div>
				<div class="c3 fl-t" @click="toCompon(2, 'KH_WDXT', 'KHG', 'KH_WDXT_BM','天稳定性')"
					@dblclick="Cclick(infoList.KHG.KH_WDXT,'KH_WDXT', 'KHG', 'KH_WDXT_BM')">{{infoList.KHG.KH_WDXT}}
				</div>
				<div class="c4 fl-t" @click="toCompon(2, 'KH_WDXY', 'KHG', 'KH_WDXY_BM','月稳定性')"
					@dblclick="Cclick(infoList.KHG.KH_WDXY,'KH_WDXY', 'KHG', 'KH_WDXY_BM')">{{infoList.KHG.KH_WDXY}}
				</div>
				<div class="c5 fl-t" @click="toCompon(2, 'KH_WDXS', 'KHG', 'KH_WDXS_BM','自定义考核稳定性')"
					@dblclick="Cclick(infoList.KHG.KH_WDXS,'KH_WDXS', 'KHG', 'KH_WDXS_BM')">{{infoList.KHG.KH_WDXS}}
				</div>
			</div>
			<div class="status_style" :style="{background:(infoList ?'':'')}" @click="toCompon(5)">
				数据采集
			</div>
		</div>
		<div class="fl-t middle">
			<div class="middle-up ">
				<div class="button-row ">
					<div class="status_style red fl-t" :style="{background:(infoList.KHG.JNKHKS ?'#000':'red')}">
						{{ infoList.KHG.JNKHKS==1?'正在考核':'等待考核 '}}
					</div>
					<div class="status_style fl-t" @click="
                  toIpt(
                    infoList.KHG.JNKHKS,
                    '考核状态',
                     'JNKHKS',
                    'KHG',
                   
                  )
                ">
						{{ infoList.KHG.JNKHKS==1?'结束考核':'开始考核 '}}
					</div>
					<!-- {{infoList.G_KHTJ.EN9?'统计使能':'统计切除'}} -->
				</div>
				<div class="row">
					<div class="col1 fl-t">考核时间设定 </div>
					<div class="col1 fl-t">考核开始时间 </div>
				</div>
				<div class="row">
					<div class="col2 fl-t" @click="
                  toIpt(
                    infoList.KHG.KH_SJ,
                    '考核时间设定',
                     'KH_SJ',
                    'KHG',
                   
                  )
                ">
						{{ infoList.KHG.KH_SJ }}
					</div>
					<div class="col2 fl-t">没找到</div>
				</div>
				<div class="row">
					<div class="col1 fl-t">考核进行时间 </div>
					<div class="col1 fl-t">考核结束时间 </div>
				</div>
				<div class="row">
					<div class="col2 fl-t">没找到.0 </div>
					<div class="col2 fl-t">0.没找到 </div>
				</div>
			</div>
			<div class="zbjd">
				<div class="row">
					指标名称和精度
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN1,
                    '汽泡液位控制精度',
                     'EN1',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN1" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_SWH,
                    '汽泡液位控制精度',
                     'KH_SWH',
                    'KHG',
                   
                  )
                ">
								{{ infoList.KHG.KH_SWH }}
							</div>
						</div>
						<div class="cname">汽泡液位控制精度</div>

					</div>
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN2,
                    '主汽温度控制精度',
                     'EN2',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN2" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_QWH,
                    '主汽温度控制精度',
                     'KH_QWH',
                    'KHG',
                   
                  )
                ">
								{{ infoList.KHG.KH_QWH }}
							</div>
						</div>
						<div class="cname">主汽温度控制精度</div>

					</div>
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN3,
                    '一次风压控制精度',
                     'EN2',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN3" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_SWL,
                    '一次风压控制精度',
                     'KH_SWL',
                    'KHG',
                   
                  )
                ">
								{{ infoList.KHG.KH_SWL }}
							</div>
						</div>
						<div class="cname">一次风压控制精度</div>

					</div>
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;">
								<!-- <el-switch  v-model="infoList.KHG.EN4"                 
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_QWL,
                    '屏过温度控制精度',
                     'KH_QWL',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_QWL}}
							</div>
						</div>
						<div class="cname">屏过温度控制精度</div>

					</div>
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN5,
                    '烟气氧量控制精度',
                     'EN5',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN5" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_O2H,
                    '烟气氧量控制精度',
                     'KH_O2H',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_O2H}}
							</div>
						</div>
						<div class="cname">烟气氧量控制精度</div>

					</div>
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;">
								<!-- <el-switch  v-model="infoList.KHG.EN6"                 
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_O2L,
                    '风量控制精度',
                     'KH_O2L',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_O2L}}
							</div>
						</div>
						<div class="cname">风量控制精度</div>

					</div>
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN7,
                    '主汽压力控制精度',
                     'EN7',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN7" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_QYH,
                    '主汽压力控制精度',
                     'KH_QYH',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_QYH}}
							</div>
						</div>
						<div class="cname">主汽压力控制精度</div>

					</div>
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;">
								<!-- <el-switch  v-model="infoList.KHG.EN8"
                
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_QYL,
                    '主汽流量控制精度',
                     'KH_QYL',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_QYL}}
							</div>
						</div>
						<div class="cname">主汽流量控制精度</div>

					</div>
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN9,
                    '炉膛负压控制精度',
                     'EN9',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN9" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_FYH,
                    '炉膛负压控制精度',
                     'KH_FYH',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_FYH}}
							</div>
						</div>
						<div class="cname">炉膛负压控制精度</div>

					</div>
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN10,
                    '脱硝控制精度',
                     'EN10',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN10" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_FYL,
                    '脱硝控制精度',
                     'KH_FYL',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_FYL}}
							</div>
						</div>
						<div class="cname">脱硝控制精度</div>

					</div>
				</div>
				<div class="row1">
					<div class="col1 fl-t">
						<div>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN11,
                    '脱硫控制精度',
                     'EN11',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN11" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>
							<div class="fl-t "
								style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;" @click="
                  toIpt(
                    infoList.KHG.KH_FYL,
                    '脱硫控制精度',
                     'KH_FYL',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_FYL}}
							</div>
						</div>
						<div class="cname">脱硫控制精度</div>

					</div>
					<!-- <div class="col1 fl-t">
              <div>
                  <div class="fl-t "  style="width:3vw;height:3vh;margin-left:2vw;"
                   @click="
                  toIpt(
                    infoList.KHG.EN11,
                    '脱硫控制精度',
                     'EN11',
                    'KHG',
                   
                  )
                ">
               <el-switch  v-model="infoList.KHG.EN11"
                
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch>
                  </div>
              <div class="fl-t " style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;"
                   @click="
                  toIpt(
                    infoList.KHG.KH_TLJD,
                    '脱硫控制精度',
                     'KH_TLJD',
                    'KHG',
                   
                  )
                ">{{ infoList.KHG.KH_TLJD}}
                </div>
              </div>
              <div class="cname">脱硫控制精度</div>
             
            </div> -->
					<div class="col2 fl-t">
						<div>
							<span class="ct">
								制粉系统
							</span>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN6,
                    ' 制粉系统',
                     'EN6',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN6" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>

						</div>
						<div>
							<span class="ct">
								周 界 风
							</span>
							<div class="fl-t " style="width:3vw;height:3vh;margin-left:2vw;" @click="
                  toIpt(
                    infoList.KHG.EN9,
                    '周 界 风',
                     'EN9',
                    'KHG',
                   
                  )
                ">
								<el-switch v-model="infoList.KHG.EN9" active-color="#29DFAE" inactive-color="red"
									disabled>
								</el-switch>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="zzkl fl-t">
			<div class="row" style="margin-top:1vh;">总自控率%</div>
			<div class="zs1"></div>
			<div> <span class="ck">主汽温度自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_QWZKL', 'KHG', 'KH_QWZKL_BM','汽温自控率')"
					@dblclick="Cclick(infoList.KHG.KH_QWZKL,'KH_QWZKL', 'KHG', 'KH_QWZKL_BM')">{{infoList.KHG.KH_QWZKL}}
				</span> </div>
			<div> <span class="ck">汽包水位自控率 </span> <span class="cv"
					@click="toCompon(2, 'KH_GSZKL', 'KHG', 'KH_GSZKL_BM','给水自控率')"
					@dblclick="Cclick(infoList.KHG.KH_GSZKL,'KH_GSZKL', 'KHG', 'KH_GSZKL_BM')">{{infoList.KHG.KH_GSZKL}}
				</span> </div>
			<div> <span class="ck">锅炉负荷自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_GFZKL', 'KHG', 'KH_GFZKL_BM','给煤自控率')"
					@dblclick="Cclick(infoList.KHG.KH_GFZKL,'KH_GFZKL', 'KHG', 'KH_GFZKL_BM')">{{infoList.KHG.KH_GFZKL}}
				</span> </div>
			<div> <span class="ck">炉膛负压自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_FYZKL', 'KHG', 'KH_FYZKL_BM','负压自控率')"
					@dblclick="Cclick(infoList.KHG.KH_FYZKL,'KH_FYZKL', 'KHG', 'KH_FYZKL_BM')">{{infoList.KHG.KH_FYZKL}}
				</span> </div>
			<div> <span class="ck">一次风压自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_YFZKL', 'KHG', 'KH_YFZKL_BM','一次风自控率')"
					@dblclick="Cclick(infoList.KHG.KH_YFZKL,'KH_YFZKL', 'KHG', 'KH_YFZKL_BM')">{{infoList.KHG.KH_YFZKL}}
				</span> </div>
			<div> <span class="ck">烟气氧量自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_SFZKL', 'KHG', 'KH_SFZKL_BM','送风自控率')"
					@dblclick="Cclick(infoList.KHG.KH_SFZKL,'KH_SFZKL', 'KHG', 'KH_SFZKL_BM')">{{infoList.KHG.KH_SFZKL}}
				</span> </div>
			<div> <span class="ck">制粉系统自控率</span> <span class="cv"
					@click="toCompon(2, 'KH_ZFZKL', 'KHG', 'KH_ZFZKL_BM','制粉系统自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZFZKL,'KH_ZFZKL', 'KHG', 'KH_ZFZKL_BM')">{{infoList.KHG.KH_ZFZKL}}
				</span> </div>
			<div> <span class="ck">脱硝自控率</span> <span class="cv" @click="toCompon(2, 'KH_TXZKL', 'KHG', 'KH_NOXZKL_BM')"
					@dblclick="Cclick(infoList.KHG.KH_TXZKL,'KH_TXZKL', 'KHG', 'KH_NOXZKL_BM')">{{infoList.KHG.KH_TXZKL}}
				</span> </div>
			<div> <span class="ck">脱硫自控率</span> <span class="cv" @click="toCompon(2, 'KH_TLZKL', 'KHG', 'KH_SO2ZKL_BM')"
					@dblclick="Cclick(infoList.KHG.KH_TLZKL,'KH_TLZKL', 'KHG', 'KH_SO2ZKL_BM')">{{infoList.KHG.KH_TLZKL}}
				</span> </div>
			<div class="zs2">
				<div class="zs2-1" @click="toCompon(2, 'KH_ZZKL', 'KHG', 'KH_ZZKL_BM','总自控率')"
					@dblclick="Cclick(infoList.KHG.KH_ZZKL,'KH_ZZKL', 'KHG', 'KH_ZZKL_BM')">{{infoList.KHG.KH_ZZKL}}
				</div>
				<div class="zs2-2">总自控率</div>

			</div>
		</div>
		<div class="bwdzb fl-t">
			<div class="row">班稳定性指标</div>
			<div class="zs1">
			</div>
			<div class="zs2">
				<div class="zs21" @click="toCompon(2, 'KH_WDXB', 'KHG', 'KH_WDXB_BM','班稳定性')"
					@dblclick="Cclick(infoList.KHG.KH_WDXB,'KH_WDXB', 'KHG', 'KH_WDXB_BM')">{{infoList.KHG.KH_WDXB}}
				</div>
				<div class="zs22">稳定性指标</div>
			</div>
		</div>
		<div class="rsxl fl-t">
			<div class="row">燃烧效率统计</div>

			<div class="zs1">
				<div class="zs11" @click="toCompon(2, 'RSXLSS', 'KHG', 'RSXLSS_BM','燃烧效率')"
					@dblclick="Cclick(infoList.KHG.RSXLSS,'KH_ZZKL', 'KHG', 'RSXLSS_BM')">{{infoList.KHG.RSXLSS}}
				</div>
				<div class="zs12">效率统计</div>
			</div>
			<div class="zs2">
				<span class="zs21">
					{{infoList.KHG.KH_ZZKL >=90 ? 'A': infoList.KHG.KH_ZZKL <90 && infoList.KHG.KH_ZZKL >=70 ? 'B' : infoList.KHG.KH_ZZKL < 70 && infoList.KHG.KH_ZZKL>= 60 ? 'C' : 'D'}}
				</span>
				<span class="zs21">
					{{infoList.KHG.KH_WDXB >=90 ? 'A': infoList.KHG.KH_WDXB <90 && infoList.KHG.KH_WDXB >=70 ? 'B' : infoList.KHG.KH_WDXB < 70 && infoList.KHG.KH_WDXB>= 60 ? 'C' : 'D'}}
				</span>
				<span class="zs21">
					{{infoList.KHG.KH_WDXB >=90 ? 'A': infoList.KHG.RSXLSS <90 && infoList.KHG.RSXLSS >=70 ? 'B' : infoList.KHG.RSXLSS < 70 && infoList.KHG.RSXLSS>= 60 ? 'C' : 'D'}}
				</span>
			</div>
		</div>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList">
		</Manual>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		<MFSJCJ v-if="isSJshow" @sendStatus="isSJshow = false" :infoList="infoList">
		</MFSJCJ>

	</div>
</template>



<script>
	import inputVal from "@/components/inputVal.vue" //输入框组件
	import MFSJCJ from "@/views/MfBoiler/MFSJCJ/index.vue" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "MflSecurity",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual,
			MFSJCJ
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				isSJshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'mfkfirstcontol'
							break
						case 3:
							pathname = 'mfRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'JRLParam02', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			DataJson(data) {
				console.log(data)
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					console.log(str.charAt(str.length - 1))
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无操作权限');
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type);
				}

			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
						case 5:
							return this.isSJshow = true
					}
				}, 300);
			},
		}
	}
</script>


<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	::v-deep {
		.el-switch.is-disabled .el-switch__core {
			cursor: pointer !important;
		}

		.el-switch.is-disabled {
			opacity: 1 !important;
		}
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 26vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.sjxs {
		width: 33vw;
		height: 79vh;
		margin-left: 1.5vw;
		background-image: url("~@/assets/images/运行考核_底图01.png");
		background-size: 100% 100%;
		margin-top: 1vh;

		.row {
			padding-left: 3vw;
			width: 100%;
			height: 4vh;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #ffffff;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
		}

		.throw {
			width: 100%;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0vh;
			color: #458ca4;
			padding: 2px;
			padding-left: 1vw;

			.fl-t {
				width: 6vw;
				text-align: center;
			}

			.c1 {
				width: 7vw;
			}

			.c5 {
				width: 6vw;
				cursor: pointer;
			}
		}

		.tdrow {
			width: 100%;
			height: 5vh;
			line-height: 5vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #00e4ff;
			padding: 2px;
			padding-left: 1vw;
			align-content: center;
			cursor: pointer;

			.fl-t {
				width: 6vw;
				text-align: center;
			}

			.c1 {
				width: 7vw;
				align-content: center;
				color: #8aeaff;
			}

			.c5 {
				width: 5vw;
			}
		}

		.status_style {
			margin-left: 0.5vw;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vw;
			margin-top: 14vh;
			margin-left: 12vw;
			width: 7.5vw;
			height: 4vh;
			text-align: center;
			background-color: #00ff8a;
			border-radius: 0vh;
			border: solid 0vh #00fc98;
			cursor: pointer;
		}

		.red {
			border: 1px solid #ff0d1e;
			color: #ff0d1e;
		}


	}

	.middle {
		margin: 1vh 1vw;
		margin-bottom: 5px;
		width: 30vw;
		height: 79vh;

	}

	.zbjd {
		width: 30vw;
		height: 60vh;
		background-image: url("~@/assets/images/运行考核_底图03.png");
		background-size: 100% 100%;
		margin-top: 1.5vh;

		.row {
			padding-left: 2vw;
			width: 100%;
			height: 4vh;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #ffffff;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
		}

		.row1 {
			width: 30vw;
			height: 6vh;
			padding: 1vh 1vw;

			.col1 {
				width: 10vw;
				height: 5.5vh;
				background-image: url("~@/assets/images/运行考核-指标.png");
				background-size: 100% 100%;
				margin-top: 1vh;
				margin-left: 2vw;
				padding: 1vh 0.5vw;

				.cv {
					margin-top: -4px;
					font-family: PingFang-SC-Regular;
					font-size: 19px;
					font-weight: normal;
					font-stretch: normal;

					letter-spacing: 0vw;
					color: #00ffb4;
					float: right;
					cursor: pointer;
				}

				.cname {
					width: 10vw;
					height: 4vh;
					text-align: center;
					font-family: PingFang-SC-Regular;
					font-size: 1.8vh;
					font-weight: normal;
					font-stretch: normal;
					line-height: 4vh;
					letter-spacing: 0vw;
					color: #8aeaff;
				}
			}

			.col2 {
				width: 10vw;
				height: 5.5vh;
				margin-top: 1vh;
				margin-left: 2vw;
				padding: 1vh 0.5vw;
				cursor: pointer;

				.ct {

					width: 3vw;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vh;
					letter-spacing: 0vw;
					color: #ffffff;
				}
			}

		}
	}

	.middle-up {
		width: 30vw;
		height: 16vh;
		padding-top: 1vh;
		background-image: url("~@/assets/images/jrl-evaluate1.png");
		background-size: 100% 100%;

		.button-row {
			width: 30vw;
			height: 3vh;
			margin: 0vh 1vw;
			margin-bottom: 5px;

			.status_style {
				margin-left: 0.5vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3vh;
				letter-spacing: 0vw;

				width: 7.5vw;
				height: 3vh;
				text-align: center;
				background-color: #0085eb;
				border-radius: 0vh;
				border: solid 0vh #00fc98;
				margin: 0vh 3.2vw;
			}

			.red {
				border: 1px solid #ff0d1e;
				background-color: #ff0d1e;
			}
		}

		.row {
			width: 30vw;
			height: 3vh;
			margin: 0vh 1vw;

			.col1 {
				width: 6vw;
				margin-left: 0.5vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3vh;
				letter-spacing: 0vw;
				color: #458ca4;
				margin: 1px 4vw;
			}

			.col2 {
				width: 6vw;
				margin-left: 0.5vw;
				font-family: PingFang-SC-Regular;
				font-size: 18px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vw;
				color: #ffffff;
				margin: 1px 4vw;
				text-align: center;
				cursor: pointer;
			}

			.red {
				border: 1px solid #ff0d1e;
				background-color: #ff0d1e;
			}
		}
	}

	.zzkl {
		width: 30vw;
		height: 36vh;
		background-image: url("~@/assets/images/运行考核_底图04.png");
		background-size: 100% 100%;
		margin-top: 1.5vh;

		.row {
			width: 30vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 1.5vh;
			letter-spacing: 0vw;
			color: #ffffff;
			padding-left: 2.5vw;
			margin-bottom: 1vh;
		}

		.ck {

			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vw;
			color: #8aeaff;
			margin-left: 1vw;
		}

		.cv {
			margin-left: 3vw;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vw;
			color: #00e4ff;
			cursor: pointer;
			margin-top: 3vh;
		}

		.zs1 {
			float: right;
			width: 6vw;
			height: 12vh;
			background-image: url("~@/assets/images/jrl-evaluate9.png");
			background-size: 100% 100%;
			margin-top: 1.5vh;
			margin-right: 10vh;
		}

		.zs2 {
			float: right;
			width: 9vw;
			height: 12vh;
			background-image: url("~@/assets/images/jrl-evaluate8.png");
			background-size: 100% 100%;
			margin-top: -14vh;
			margin-right: 7vh;

			.zs2-1 {
				font-family: PingFang-SC-Regular;
				font-size: 2vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;
				letter-spacing: 0vw;
				color: #00e4ff;
				margin-top: 2vh;
				margin-left: 3vw;
				cursor: pointer;
			}

			.zs2-2 {

				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;
				letter-spacing: 0vw;
				color: #8aeaff;
				margin-top: 1vh;
				margin-left: 2.4vw;
			}
		}
	}

	.bwdzb {
		width: 30vw;
		height: 18vh;
		background-image: url("~@/assets/images/运行考核_底图05.png");
		background-size: 100% 100%;
		margin-top: 1.5vh;

		.row {
			width: 30vw;
			height: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 1.5vw;
			letter-spacing: 0vw;
			color: #ffffff;
			padding-left: 1.8vw;
			margin-bottom: 1vh;
		}

		.zs1 {
			float: left;
			width: 6vw;
			height: 12vh;
			margin-top: 0.5vh;
			margin-left: 6vw;
			background-image: url("~@/assets/images/MFL_班稳定性切图.png");
			background-size: 100% 100%;
		}

		.zs2 {
			float: left;
			width: 6vw;
			height: 12vh;
			margin-top: 0.5vh;
			margin-left: 4vw;

			.zs21 {
				width: 4vw;
				height: 2vw;
				font-family: PingFang-SC-Regular;
				font-size: 2vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 1vw;
				letter-spacing: 0vw;
				color: #00e4ff;
				margin-top: 2.5vh;
				cursor: pointer;
			}

			.zs22 {
				width: 5vw;
				height: 1vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 1vw;
				letter-spacing: 0vw;
				color: #288aab;
			}
		}
	}

	.rsxl {
		width: 30vw;
		height: 18vh;
		background-image: url("~@/assets/images/运行考核_底图06.png");
		background-size: 100% 100%;
		margin-top: 1.5vh;

		.row {
			width: 30vw;
			height: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 1.5vw;
			letter-spacing: 0vw;
			color: #ffffff;
			padding-left: 1.8vw;
			margin-bottom: 1vh;
		}

		.zs1 {
			float: left;
			width: 7vw;
			height: 12vh;
			margin-top: 0.5vh;
			margin-left: 6vw;
			background-image: url("~@/assets/images/MFL_效率统计.png");
			background-size: 100% 100%;

			.zs11 {
				margin-top: 1vh;
				width: 7vw;
				height: 3vh;
				font-family: PingFang-SC-Regular;
				font-size: 1.8vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3vh;
				letter-spacing: 0vw;
				color: #00e4ff;
				text-align: center;
				cursor: pointer;
			}

			.zs12 {
				width: 7vw;
				height: 2vh;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;
				letter-spacing: 0vw;
				color: #288aab;
				text-align: center;
			}
		}

		.zs2 {
			float: left;
			width: 8vw;
			height: 6vh;
			margin-top: 2.5vh;
			margin-left: 6vw;
			padding-left: 0.8vw;
			background-image: url("~@/assets/images/MFL_性能总指标.png");
			background-size: 100% 100%;

			.zs21 {
				width: 6vw;
				height: 2vh;
				font-family: PingFang-SC-Regular;
				font-size: 2vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 6vh;
				letter-spacing: 0vw;
				color: #8aeaff;
				margin-right: 1.2vw;

			}
		}

	}
</style>
